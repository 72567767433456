import { faker } from '@faker-js/faker';
import React, {useState, useEffect, useCallback, useContext} from 'react';
import { Helmet } from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  TextField
} from '@mui/material';

import { camelCase } from 'lodash'

// components
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
// sections
import { EnvelopeListHead, EnvelopeListToolbar } from '../../sections/@dashboard/envelope/index';

import GroupCard from './GroupCard'

import { EntityContext } from '../../entity';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'signersNumber', label: 'Assinantes', alignRight: false },
  { id: 'signersMinimum', label: 'Minimo de assinantes', alignRight: false },
  { id: 'signatureType', label: 'Tipo de assinatura', alignRight: false },
  { id: 'action', label: '', alignRight: false },
];


export default function GroupList(props) {
  const navigate = useNavigate();

    const [page, setPage] = useState(0);

    const [selected, setSelected] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [signerList, setSignerList] = useState([])

    const [groupName, setGroupName] = useState('')
    const [signerDocumentNumber, setSignerDocumentNumber] = useState('')
    const [signerEmail, setSignerEmail] = useState('')

	const envelopeKey = props.envelopeKey

  const { getEnvelope, getGroupByName, VinculateGroupToEnvelope, sendToSignature, DeleteGroupFromEnvelope } = useContext(EntityContext)

  const navigateToDone = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/done');
  };

  useEffect(() => {
    getSigners()
    }, [])

	const camelizeKeys = (obj) => {

		let finalResponse = obj

		if (Array.isArray(obj)) {
			finalResponse = obj.map(v => camelizeKeys(v));
		} else if (obj != null && obj.constructor === Object) {
			finalResponse = Object.keys(obj).reduce(
			(result, key) => ({
			  ...result,
			  [camelCase(key)]: camelizeKeys(obj[key]),
			}),
			{},
		  );
		}
		return finalResponse;
	  };
	
    const getSigners = useCallback(async () => {
	
      getEnvelope(envelopeKey)
      .then((data) => {
        
        setSignerList(data.groups)
      })
	
	  }, [])

	  const vinculateSignerToEnvelope = async (groupNameToCheck, signatureType) => {

      getGroupByName(groupNameToCheck)
      .then((data) => {
        VinculateGroupToEnvelope(envelopeKey, data.group.group_key, signatureType)
        .then((data) => {
          
          getSigners()
        })
    })
		
	  }

    const handleGroupRemove = async(groupKey) => {

      DeleteGroupFromEnvelope(envelopeKey, groupKey)
      .then((data) => {
        getSigners()
      })

    }

	  const sendToSignatureButton = async () => {

      if (signerList.length === 0) {

        props.changeStep(2)
  
      } else {

        sendToSignature(envelopeKey)
        .then((data) => {
          
          props.changeStep(3)
        })

      }
		
	  }

    const previousStep = async () => {

        props.changeStep(1)

    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = signerList.map((n) => n.name);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - signerList.length) : 0;

    return (
        <>
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Button variant="contained"
                onClick={previousStep}>
                    Revisar documentos
                </Button>
                <Button variant="contained"
                onClick={_ => sendToSignatureButton()}>
                    Enviar para assinatura
                </Button>
            </Stack>

            < GroupCard vinculateSignerToEnvelope={vinculateSignerToEnvelope}/>

            <Box sx={{ width: '100%' }}>
            <Card>
            <EnvelopeListToolbar numSelected={selected.length} />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                    <EnvelopeListHead
                    headLabel={TABLE_HEAD}
                    rowCount={signerList.length}
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                    {signerList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            
                        const selectedUser = selected.indexOf(row.group_key) !== -1;

                        return (
                        <TableRow hover key={row.group_key} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, groupName)} />
                            </TableCell>

                            <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                {row.group_name}
                                </Typography>
                            </Stack>
                            </TableCell>

                            <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                {row.number_of_signers}
                                </Typography>
                            </Stack>
                            </TableCell>

                            <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                {row.signers_minimum}
                                </Typography>
                            </Stack>
                            </TableCell>

                            <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                {row.signature_type}
                                </Typography>
                            </Stack>
                            </TableCell>

                            <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={_ => handleGroupRemove(row.group_key)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            </IconButton>
                            </TableCell>
                        </TableRow>
                        );
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                        </TableRow>
                    )}
                    </TableBody>

                </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={signerList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Card>
            </Box>


        </Container>
        </>
    );
}
