import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import DefaultLayout from './layouts/default'
import SignatureLayout from './layouts/signature';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import TemplatesPage from './pages/TemplatesPage';
import DashboardAppPage from './pages/DashboardAppPage';
import EntitiesPage from './pages/EntitiesPage';
import SignaturePage from './pages/SignaturePage'
import NewEnvelope from './pages/newEnvelope'
import SignatureFinishedPage from './pages/signatureFinishedPage'
import SignatureManagementMainPage from './pages/SignatureManagementMainPage'
import IntegrationPage from './pages/IntegrationPage'
import CreateEntityForm from './pages/NewEntityPage'
import EnvelopesPage from './pages/EnvelopesPage'
import UpdateEnvelopePage from './pages/EnvelopeUpdatePage'
import SignersPage from './pages/SignersPage';
import GroupsPage from './pages/GroupsPage';
import GroupEditPage from './pages/GroupEditPage'
import ProfilePage from './pages/ProfilePage';
import DonePage from './pages/DonePage';
import PendingSignaturesPage from './pages/PendingSignaturesPage'
import AutomaticSealPage from './pages/AutomaticSealPage'
import SignatureConfirmationPage from './pages/SignatureConfirmationPage'
import IcpSignatureConfirmationPage from './pages/icpSignatureConfirmationPage'
import BatchSignatureConfirmationPage from './pages/BatchSignatureConfirmationPage'
import PlansPage from './pages/PlansPage';
import XpressSignatureListPage from './pages/XpressSignatureListPage';
import PlanManagementPage from './pages/PlanManagementPage';
import EnvelopeGroupEditPage from './pages/EnvelopeGroupEditPage';

// ----------------------------------------------------------------------

export default function Router(props) {

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: props.isAuth ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        { element: <Navigate to="/dashboard/entities" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'entities', element: <EntitiesPage /> },
        { path: 'signatures/management', element: <SignatureManagementMainPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'templates', element: <TemplatesPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'entity', element: <CreateEntityForm /> },
        { path: 'signers', element: <SignersPage /> },
        { path: 'groups', element: <GroupsPage /> },
        { path: 'group/:groupKey', element: <GroupEditPage /> },
        { path: 'envelope/group/:groupKey', element: <EnvelopeGroupEditPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'envelopes', element: <EnvelopesPage /> },
        { path: 'xpress/signature', element: <XpressSignatureListPage /> },
        { path: 'envelope/:envelopeKey', element: <UpdateEnvelopePage /> },
        { path: 'envelope', element: <NewEnvelope /> },
        { path: 'done', element: <DonePage /> },
        { path: 'configuration', element: <IntegrationPage /> },
        { path: 'Automaticseal', element: <AutomaticSealPage /> },
        { path: 'pendingSignatures', element: <PendingSignaturesPage /> },
        { path: 'plan/management', element: <PlanManagementPage /> },
      ],
    },
    {
      path: 'login',
      element: props.isAuth ? <Navigate to="/dashboard" replace /> : <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'plans', element: <PlansPage /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/sign",
      element: <DefaultLayout />, // props.isAuth ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        { element: <Navigate to="/sign/:signerKey" />, index: true },
        { path: ':signerKey', element: <SignaturePage /> },
      ],
    },
    {
      path: "/signature",
      element: <SimpleLayout />, // props.isAuth ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        { element: <Navigate to="/sign/:signerKey" />, index: true },
        { path: 'finish', element: <SignatureFinishedPage /> },
        { path: ':signerKey/token', element: <SignatureConfirmationPage /> },
        { path: ':signerKey/icp', element: <IcpSignatureConfirmationPage /> },
        { path: ':batchKey/batch/token', element: <BatchSignatureConfirmationPage /> },

      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
