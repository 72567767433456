import { faker } from '@faker-js/faker';
import React, {useState, useEffect, useCallback} from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Box,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from '@mui/material';

// components
import Iconify from '../iconify';

import NewGroupForm from './NewGroupForm'

export default function GroupCard(props) {

    const [openExistentGroup, setOpenExistentGroup] = useState(false)
    const [openNewGroup, setOpenNewGroup] = useState(false)
    const [groupNameToCheck, setGroupNameToCheck] = useState('')
    const [signatureType, setSignatureType] = useState('email'); // Estado para o tipo de assinatura

    const handleChangeSignatureType = (event) => {
      setSignatureType(event.target.value);
    };

    const HandleOpenForm = (event) => {

        switch(event) {
            case 'new':
                setOpenNewGroup(!openNewGroup)
                setOpenExistentGroup(false)
              break;
            case 'existent':
                setOpenExistentGroup(!openExistentGroup)
                setOpenNewGroup(false)
              break;
            default:
                setOpenNewGroup(false)
                setOpenExistentGroup(false)
          }

    }

    const isMobile = useMediaQuery('(max-width:600px)'); // Defina um breakpoint para mobile

    return (
            <Stack justifyContent="space-between" mb={5} >
                <Card>
                    <Typography sx={{ m: 2 }} >Adicionar Grupo</Typography>
                      <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                        <TextField sx={{ m: 1, width: '90%' }}
                        fullWidth={isMobile} // Ocupa toda a largura em mobile
                        id="outlined-basic" 
                        label="Nome do Grupo" 
                        value={groupNameToCheck}
                        onChange={e => setGroupNameToCheck(e.target.value)}
                        variant="outlined"/>

                    <FormControl fullWidth={isMobile} sx={{ m: 1, width: '90%' }} variant="outlined"> {/* FormControl para o Select */}
                      <InputLabel id="signature-type-label">Tipo de Assinatura</InputLabel>
                      <Select
                        labelId="signature-type-label"
                        id="signature-type"
                        value={signatureType}
                        label="Tipo de Assinatura"
                        onChange={handleChangeSignatureType}
                      >
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="icp">Certificado Digital</MenuItem>
                        <MenuItem value="selfie">Selfie</MenuItem>
                        <MenuItem value="whatsapp">Whatsapp</MenuItem>
                        <MenuItem value="sms">SMS</MenuItem>
                      </Select>
                    </FormControl>

                    <Box sx={{ mt: 2 }}>
                        <Button 
                        sx={{ m: 2 }} 
                        fullWidth={isMobile} 
                        variant="contained" 
                        component="label" 
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={_ => props.vinculateSignerToEnvelope(groupNameToCheck, signatureType)}>
                        Adicionar
                        </Button>
                      </Box>
                      </Stack>
                </Card>
            </Stack>
    );
}