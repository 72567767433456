import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import LacunaWebPKI from 'web-pki';

import {
  Stack,
  Typography,
  Container,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  CircularProgress // Importe CircularProgress
} from '@mui/material';

function IcpSignatureConfirmationPage(props) {
  const { signerKey } = useParams();

  const [certificates, setCertificates] = useState([]);
  const [selectedCert, setSelectedCert] = useState('');
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento

  useEffect(() => {
    const pki = new LacunaWebPKI();
    pki.init(() => {
      pki.listCertificates().success((certs) => {
        setCertificates(certs);
        setLoading(false); // Define loading como false após carregar os certificados
      }).error((error) => { // Trate erros
        console.error("Erro ao listar certificados:", error);
        setLoading(false); // Mesmo em caso de erro, pare o loading
        // Exiba uma mensagem de erro para o usuário, se necessário.
      });
    });
  }, []);

  const handleReadCert = () => {
    if (selectedCert) {
      const pki = new LacunaWebPKI();
      pki.readCertificate(selectedCert).success((certEncoding) => {
        console.log('Result:', certEncoding);
      });
    }
  };

  const handleSelectCert = (event) => {
    setSelectedCert(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Confirmar Assinatura</title>
      </Helmet>

      <Container maxWidth="md"> {/* Largura do container ajustada */}
        <Stack alignItems="center" justifyContent="center" spacing={3} sx={{ mt: 8, mb: 8 }}> {/* Margem superior e inferior ajustada com sx */}
          <Typography variant="h5" gutterBottom>Selecione um Certificado:</Typography> {/* Typography com variant e gutterBottom */}

          {loading ? ( // Exibe o CircularProgress enquanto carrega
            <CircularProgress />
          ) : (
            <FormControl fullWidth>
              <InputLabel id="cert-select-label">Selecione um certificado</InputLabel>
              <Select
                labelId="cert-select-label"
                id="cert-select"
                value={selectedCert}
                label="Selecione um certificado"
                onChange={handleSelectCert}
              >
                <MenuItem value="">Selecione um certificado</MenuItem>
                {certificates.map((cert) => (
                  <MenuItem key={cert.thumbprint} value={cert.thumbprint}>
                    {cert.subjectName} (emitido por {cert.issuerName})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Button variant="contained" onClick={handleReadCert} disabled={!selectedCert} fullWidth> {/* Botão estilizado e desabilitado se nenhum certificado selecionado */}
            Assinar
          </Button>

        </Stack>
      </Container>
    </>
  );
}

export default IcpSignatureConfirmationPage;