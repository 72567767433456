import PropTypes from 'prop-types';
import { useContext } from 'react';
import {useNavigate} from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography, Button } from '@mui/material';
// utils
import { EntityContext } from '../../entity';
import { bgBlur } from '../../utils/cssStyles';
// components
import Logo from '../logo';
// ----------------------------------------------------------------------

const NAV_WIDTH = 0;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function SignatureHeader(props) {

  const { requestSignatureToken } = useContext(EntityContext)

  const navigate = useNavigate();

  const navigateToSignatureConfirmation = () => {
    if (props.envelope.signature_type === 'icp') {
    // 👇️ navigate to /contacts
    navigate(`/signature/${props.signerKey}/icp`);
    } else {
      // 👇️ navigate to /contacts
      navigate(`/signature/${props.signerKey}/token`);
    }
  };

  const handleTokenRquest = async () => {

    if (props.envelope.signature_type === 'icp') {
      navigateToSignatureConfirmation()
    } else {
      const payload = {
        "envelope_signer_key": props.signerKey,
      }
  
      requestSignatureToken(payload)
        .then(data => {
          
          navigateToSignatureConfirmation()
        })
    }

  }

  return (
    <StyledRoot>
      <StyledToolbar>
        <Stack
            direction="row"
            alignItems="center"
            jjustifyContent="space-between"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
        <Logo/>
        <Typography variant="h4" gutterBottom color="common.black">
              Envelope: {props.envelope ? props.envelope.envelope_name : '...'}
        </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
            <div>
                <Button 
                    variant="contained"
                    onClick={_ => handleTokenRquest()}>
                    Assinar
                </Button>
            </div>
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
